<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="allTraining.results.trainingTitle" />
        </v-col>
        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    S/N
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Subject
                  </th>
                  <th class="text-left">
                    Phone
                  </th>
                  <th class="text-left">
                    Email
                  </th>
               
                  <th class="text-left">
                    Description
                  </th>
    
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in allTraining.results.appliedUser"
                  :key="index"
                >
                  <td>{{index+1}}</td>
                  <td>{{data.fullname}}</td>
                  <td>{{data.subject}}</td>
                  <td><a :href="`tel:+977+${data.phone}`">{{data.phone}}</a></td>
                  <td><a :href="`mailto:${data.email}`">{{data.email}}</a></td>
                  <td>{{data.description}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "AppliedUser",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Training User Applied",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
  }),
  methods: {
    ...mapActions(["getSingleTraining"]),
  },
  computed: {
    ...mapGetters(["allTraining"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  created() {
    if (this.$route.params.slug) {
      this.getSingleTraining(this.$route.params.slug);
      this.headercontent.title = this.allTraining;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.imageold {
  width: 200px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
a{
    color:$tagcolor;
    text-decoration: none;
}
</style>
